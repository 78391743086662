
//const BASE_API = 'https://services.pdg-dev.com/'
const BASE_API = 'https://services.pdg-preprod.com/'
export const environment = {
  production: false,
  apiAuthUrl: BASE_API+'auth_services/api',
  apiAdminUrl: BASE_API+'admin_services/api',
  apiReseauUrl: BASE_API+'reseau_services/api',
  apiTransfertUrl: BASE_API+'transfert_services/api',
  walletApi: BASE_API+'wallet_services/api',
  apiAuthUrlMobile: BASE_API+'mobile_auth/api'
}
